






















































































































































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { INCEPTION_STATS } from "@/store/modules/recruiter/constants";
import { GET_COMPANY_DETAILS } from "@/store/modules/auth/constants";
import { AppAssessmentNames } from "@/store/modules/common/interfaces";

export default Vue.extend({
  name: "InceptionDashboardNumbers",
  data() {
    return {
      unique_jobs_icon: require("@/assets/icons/bold/document-text.svg"),
      community_members_icon: require("@/assets/icons/bold/brifecase-tick.svg"),
      registered_employees_icon: require("@/assets/icons/bold/people.svg"),
      members_percentage: "0"
    };
  },
  computed: {
    ...mapGetters("recruiter", {
      inception_stats: INCEPTION_STATS
    }),
    ...mapGetters("auth", {
      get_company: GET_COMPANY_DETAILS
    }),
    eng_assessment_version(): string {
      const version =
        this.get_company?.s3_path?.assessment_setting?.candidates_english;
      if (version === AppAssessmentNames.english_v1) return "v1";
      else if (version === AppAssessmentNames.english_v2) return "v2";
      else return "v1";
    },
    annotation_assessment_version(): string {
      const version =
        this.get_company?.s3_path?.assessment_setting?.candidates_annotation;
      if (version === AppAssessmentNames.annotation_v1) return "v1";
      else if (version === AppAssessmentNames.annotation_v2) return "v2";
      else return "v1";
    }
  },
  async mounted() {
    await this.inception_stats_fetch();
    if (
      this.inception_stats.users > 0 &&
      this.inception_stats.last_week_members > 0
    ) {
      if (
        this.inception_stats.users - this.inception_stats.last_week_members >
        0
      )
        this.members_percentage = (
          (this.inception_stats.last_week_members /
            (this.inception_stats.users -
              this.inception_stats.last_week_members)) *
          100
        ).toFixed(0);
      else
        this.members_percentage = (
          this.inception_stats.last_week_members * 100
        ).toFixed(0);
    }
  },
  methods: {
    ...mapActions("recruiter", {
      inception_stats_fetch: INCEPTION_STATS
    }),
    transform_unique_jobs_icon(icon: HTMLElement): HTMLElement {
      for (let node of icon.children) node.setAttribute("fill", "#FF8473");
      return icon;
    },
    transform_community_members_icon(icon: HTMLElement): HTMLElement {
      for (let node of icon.children) node.setAttribute("fill", "#EE49FD");
      return icon;
    },
    transform_registered_employees_icon(icon: HTMLElement): HTMLElement {
      for (let node of icon.children) node.setAttribute("fill", "#ADE3E3");
      return icon;
    },
    transform_total_candidates(icon: HTMLElement): HTMLElement {
      for (let node of icon.children) node.setAttribute("fill", "#004141");
      return icon;
    },
    get_assessments_percentage(value: string) {
      return Math.round((parseInt(value) / this.inception_stats.users) * 100);
    }
  }
});
